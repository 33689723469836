/* Basic app styles */
.app {
  text-align: center;
  font-family: Arial, sans-serif;
}

/* Header styles */
.app-header {
  background-color: #52CED3;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 20px;
}

.app-logo {
  width: 400px;
  max-width: 70%;
}

/* Main content styles */
main {
  background-color: #D196FF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
}

section {
  margin-bottom: 20px;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Input styles */
input[type="email"] {
  margin: 10px 0;
}

/* Responsive styles */
@media (max-width: 600px) {
  .app-header {
    font-size: 18px;
  }
}

/* Icon styles */
.icon {
  width: 50px;
  transition: 100ms;
  fill: #26332e;
}

.icon:hover {
  border-radius: 100%;
  fill: #0e1a25;
  transform: scale(1.25);
  transition: background-color 200ms, transform 200ms ease-out;
}

.icons-container {
  display: flex;
  width: 300px;
  max-width: 90%;
  align-items: center;
  justify-content: space-between;
  padding-top: 32px;
}

/* Image replacement styles */
.ir {
  display: block;
  overflow: visible;
  padding: 0 0 100%;
  position: relative;
  height: 0;
  width: 100%;
}

.ir>* {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

/* Specific icon hover styles */
.github:hover {
  background: #fff;
}

.email:hover {
  background: #c94235;
  fill: white;
}

.facebook:hover {
  background: #1772e6;
  fill: white;
}

.instagram:hover {
  background: linear-gradient(#bf2f9e, #f2434a);
  fill: white;
}

.twitter:hover {
  background: #1ea1f1;
  fill: white;
}
